import * as React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';
import settings from '../settings.json';
import translate from '../utils/translator'

export default function SimpleContactForm(form) {
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    data.pageUrl = form.pageUrl
    console.log('data: ', data);
    const url = settings.forms_webhook;

    setLoading(true);
    const r = await callHook({url, uid:form.formId, params:data});
    console.log(r);
    if (r && r.success === true) {
      setError(false);
    } else {
      setError(true);
    }
    setSend(true);
    setLoading(false);
  }

  if (isLoading && !isSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container py-5">
          <div className="text-center col-12 col-lg-8 mx-auto">
            <div className="loader mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }

  if (isSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          <div className="text-center col-12 col-lg-8 mx-auto">
            {!isError && (
            <>
              <h5>Danke!</h5>
              <p>Wir werden Sie in Kürze kontaktieren.</p>
            </>
            )}
            {isError && (
            <>
              <h5>Es ist ein Fehler aufgetreten</h5>
              <p>Bitte versuchen Sie es später erneut.</p>
            </>
            )}
            <div className="col-auto">
              <button onClick={()=>setSend(false)} className={"btn btn-primary"}>{isError ? "Erneut versuchen" : translate('btn_new_request')}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            placeholder="Ihr Name"
            className={'form-control'}
            {...register('name', {required: false})}
          />
        </div>
        <div className={"mt-4"}>
          <input
            type="email"
            placeholder="Ihre E-Mail-Adresse"
            className={"form-control"}
            required
            {...register('email', {required: true})}
          />
        </div>
        <div className={"mt-4"}>
          <textarea
            placeholder="Geben Sie hier Ihre Nachricht ein"
            type="text"
            rows="5"
            className={"form-control"}
            required
            {...register('text', {required: true})}
          />
        </div>
        <input type="submit" value="Nachricht senden" className={"mt-4 btn btn-primary"}/>
      </form>
		</div>
  )
}
